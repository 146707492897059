import * as React from 'react';
import { Link } from 'gatsby';

import SEO from 'components/SEO';

const NotFoundPage = () => {
  return (
    <>
      <SEO title="404 Not found" />
      <section className="bg-primary-light dark:bg-primary-dark">
        <div className="container flex flex-col items-center justify-center h-screen px-6 mx-auto md:px-24 xl:px-64">
          <h3 className="mb-4 text-xl font-bold text-left md:text-2xl text-tertiary-dark dark:text-tertiary-light">
            Page not found
          </h3>
          <p className="mb-8 text-center text-tertiary-dark dark:text-tertiary-light">
            Sorry{' '}
            <span role="img" aria-label="Pensive emoji">
              😔
            </span>{' '}
            we couldn’t find what you were looking for.
          </p>
          <Link
            to="/"
            className="flex items-center justify-center px-4 py-3 duration-200 border border-gray-200 rounded-md text-tertiary-dark dark:text-tertiary-light bg-secondary-light hover:bg-tertiary-light dark:bg-primary-dark dark:hover:bg-secondary-dark focus:outline-blue-300"
          >
            Go Home
          </Link>
        </div>
      </section>
    </>
  );
};

export default NotFoundPage;
